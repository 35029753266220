const PORT = 5000;
//const SERVER_URL = 'http://127.0.0.1:5000';
const SERVER_URL = 'https://cherry.ghlee.xyz';
const REACT_APP_GOOGLE_CLIENT_ID="434999881954-00eph6u0rse4nrhsvndoepu7d6946fk7.apps.googleusercontent.com";
//const REACT_APP_GOOGLE_CLIENT_ID="749475098575-oofm1cvi49lcepfl4qd6qmt7vcaioa3j.apps.googleusercontent.com";


module.exports = {
    PORT,
    REACT_APP_GOOGLE_CLIENT_ID,
    SERVER_URL
}
