import './mytable.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { Switch } from 'antd';

function CompoCompletedTrade(props) {

    useEffect(()=>{
        console.log('compl=', props.completedList);
    }, [props.completedList]);

    //debugger;
    //console.log('---------------compl=', props.completedList);

    function extractDateTime(ts) {
        // Create a Date object from the timestamp
        const date = new Date(ts*1000);
        // Get month (getMonth() returns 0-11, so add 1 for a 1-12 range)
        const month = date.getMonth() + 1;
      
        // Get date
        const day = date.getDate();
      
        // Get hour
        const hour = date.getHours();
      
        // Get minute
        const minute = date.getMinutes();
      
        return { month, day, hour, minute };
    }

    let cumsum_percent = 0;
    let cumsum_usd = 0;
    const render =  props.completedList && props.completedList.map( function (tinfo, index) {
        const time = extractDateTime(tinfo.ts);
        const ordertime_date = `[${time.month}/${time.day}/${time.hour}:${time.minute}]`;
        const is_maker_side = tinfo.isMakerSide===true? 'O': tinfo.isMakerSide===false? 'X' : '?';
        const profit_percent = tinfo.profit ? parseFloat(tinfo.profit) : 0;
        const profit_str = typeof profit_percent === 'number' ? profit_percent.toFixed(2) : '';
        cumsum_percent += profit_percent;
        const profit_usd = tinfo?.profit_usd ? parseFloat(tinfo.profit_usd) : 0;
        cumsum_usd += profit_usd;

        return (
            <tr key={index}>
                <td>{index}</td>
                <td>{ordertime_date}</td>
                <td>{tinfo.symbol}</td>
                <td>{tinfo.avgPrice}</td>
                <td>{tinfo.order_sign * tinfo.executedQty}</td>
                <td>{profit_str}</td>
                <td>{cumsum_percent.toFixed(2)}</td>
                <td>{profit_usd.toFixed(2)}</td>
                <td>{cumsum_usd.toFixed(2)}</td>
                <td>{is_maker_side}</td>
                <td>{tinfo?.profit_explain}</td>
            </tr>
            )
    })

    return (
        <div style={{margin:'15px'}}>
            <table className='mytable'>
                <thead>
                    <tr>
                        <th>IDX</th>
                        <th>Time</th>
                        <th>Symbol</th>
                        <th>체결 가격</th>
                        <th>체결량<br/>signed</th>
                        <th>profit</th>
                        <th>cumsum</th>
                        <th>profit<br/>usd</th>
                        <th>cumsum</th>
                        <th>Maker</th>
                        <th>State</th>
                    </tr>
                </thead>
                <tbody>
                    {props.completedList && render}
                </tbody>
            </table>
        </div>
    )
}

export default CompoCompletedTrade
