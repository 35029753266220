import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Divider } from 'antd';
import axios from "axios";
import Manual from './Sections/Manual';
import CompoPositionsList from './Sections/CompoPositions';
import CompoOrderList from './Sections/CompoOrderList';
import CompoCompletedTrade from './Sections/CompoCompletedTrade';
import {SERVER_URL} from '../../../config'
import {io} from "socket.io-client"


function LandingPage(props) {
    console.log('LandingPage start');
    const socket = useRef(null);

    const [AutoMode, setAutoMode] = useState(false);
    const [SymbolList, setSymbolList] = useState([]);
    const [DefaultSymbolIdx, setDefaultSymbolIdx] = useState(0);
    const [DataOrderList, setDataOrderList] = useState([]);
    const [DataPositionsList, setDataPositionsList] = useState([]);
    const [DataCompletedTrade, setDataCompletedTrade] = useState(null);

    const [FailCount, setFailCount] = useState(0);      //데이터를 몇번 연속적으로 못 가져왔는지 표시

    useEffect(()=>{
        getSystemParam();
        updateOrderList();
        updatePositionsList();
        updateCompletedTrade();
    }, []);


    // socket으로 받은 데이터를 처리함
    async function update_from_socket(update_data) {
        try {
            const reslen = update_data.length;
            for (let k=0; k<reslen; k++){
                const tdata = update_data[k];

                switch (tdata.type) {
                    // case 'plot':    updatePlotData();  break;
                    case 'update':  
                        console.log(`update: ${tdata.data}`);
                        if (tdata.data==='asset') {
                            console.log('update asset: ??????????????');
                            //setUpdateAsset(true);
                        }
                        else if (tdata.data==='orderlist') {
                            await updateOrderList(true);
                        }
                        else if (tdata.data==='positionslist') {
                            updatePositionsList(true);
                        }
                        else if (tdata.data==='completed_list') {
                            updateCompletedTrade(true);
                        }
                        else {
                            console.log('unknown data:', tdata.data);
                        }
                        break;
        
                    case 'alert':
                        alert(tdata.data);
                        break;

                    default:    alert('cannot parsing: '+ tdata.type);
                }
            }
            setFailCount(0);
        } catch (err) {
            console.log('get_info:ERROR:', err.message);
            setFailCount(FailCount+1);
        }

    }

 
    ////////////////////////// update functions /////////////////////////////
    async function getSystemParam() {
        const res = await axios.get("/api/get_system_params");
        console.log('get_system_params=', res.data);
        if (res.data.success){
            setAutoMode(res.data.contents.automode);
            setSymbolList(res.data.contents.symbol_list);
        }
    }

    // async function updateAsset() {
    //     const res = await axios.get("/api/get_asset");
    //     console.log('get_asset=', res.data.contents);
    //     if (res.data.success){
    //         setDataAsset(res.data.contents);
    //         setUpdateAsset(false);
    //     }
    // }

    // server의 context.orderlist를 받아옴
    async function updateOrderList() {
        const res = await axios.get("/api/get_orderlist");
        console.log('get_orderlist=', res.data);
        if (res.data.success) {
            setDataOrderList(res.data.contents);
            //setUpdateOrderList(false);
        }
    }


    // server의 positions_list를 받아옴
    async function updatePositionsList() {
        const res = await axios.get("/api/get_positions_list");
        console.log('get_positions_list=', res.data);
        if (res.data.success) {
            setDataPositionsList(res.data.contents);
        }
        //setUpdatePositionsList(false);
    }

    
    async function updateCompletedTrade() {
        const dataToCommit = {limit: 40};
        const res = await axios.post("/api/get_completed_list", dataToCommit);
        console.log('get_completed_list=', res.data);
        if (res.data.success){
            setDataCompletedTrade(res.data.contents);
            //setUpdateCompletedTrade(false);
        }
    }


    ///////////////////// SOCKET ////////////////////////////////
    // connect
    useEffect(() => {
        if (!socket.current) {
            const dataToCommit = {
                token: 'testing',
            };
            const newSocket = io(SERVER_URL, { query: dataToCommit });
            newSocket.on('connect', () => {
                console.log('Connected to the server');
            });

            socket.current = newSocket;
        }

        // 컴포넌트 언마운트시 소켓 연결을 해제합니다.
        return () => {
            if (socket.current) {
                socket.current.disconnect();
                socket.current = null;
            }
        };
    }, []);


    // update_data를 처리하는 함수를 등록함
    useEffect(() => {
        if (socket.current) {
            socket.current.on('update_data', (res) => {
                console.log('update_data:', res);
                update_from_socket(res);
            });
        }
    }, [socket.current]);


    // 브라우저의 reload를 누르면 되므로 필요없을 듯...
    async function send_update_request(){
        const dataToCommit = {
            what:     'anything',
        }
        
        if (socket.current) {
            socket.current.emit('update', dataToCommit);
        }
    }
    ///////////////////// END OF SOCKET ////////////////////////////////



    return (
        <div>
            <hr/>
            <Row justify="space-around">
                <Col sm={24} md={8}>
                    <Manual DefaultSymbol={SymbolList[DefaultSymbolIdx]} AutoMode={AutoMode} setAutoMode={setAutoMode}/>
                </Col>
            </Row>
            <Divider plain style={{color:'blue', fontWeight:'bold'}}>Order List</Divider>
            <Row justify='space-around'>
                <Col span={22}>
                    <CompoOrderList OrderList={DataOrderList}/>
                </Col>
            </Row>
            <Divider plain style={{color:'blue', fontWeight:'bold'}}>Positions List</Divider>
            <Row justify="space-around">
                <Col sm={24} md={8}>
                    <CompoPositionsList PositionsList={DataPositionsList}/>
                </Col>
            </Row>
            <hr/>
            <Row justify='space-around'>
            <Col span={22}>
                    <CompoCompletedTrade completedList={DataCompletedTrade}/>
                </Col>
            </Row>
        </div>
    )
}

export default LandingPage
